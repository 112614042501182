import { FunctionComponent, ReactNode, ChangeEventHandler, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'dibs-react-intl';
import { BasicSelect } from 'dibs-elements/exports/BasicSelect';
import { Tooltip } from 'dibs-elements/exports/Tooltip';
import QuestionMarkEmpty from 'dibs-icons/exports/legacy/CircleQuestionMarkEmpty';
import QuestionMarkFilled from 'dibs-icons/exports/legacy/CircleQuestionMarkFilled';

import styles from './styles/LoginVerticalSelect.scss';

const messages = defineMessages({
    furniture: {
        id: 'aaa.login.verticalSelect.furniture',
        defaultMessage: 'Furniture',
    },
    jewelry: {
        id: 'aaa.login.verticalSelect.jewelry',
        defaultMessage: 'Jewelry',
    },
    fashion: {
        id: 'aaa.login.verticalSelect.fashion',
        defaultMessage: 'Fashion',
    },
    art: {
        id: 'aaa.login.verticalSelect.art',
        defaultMessage: 'Fine Art',
    },
});

type Props = {
    touched: boolean;
    error: ReactNode;
    label?: ReactNode;
    value: string;
    onBlur: (field: string) => void;
    onChange: ChangeEventHandler<HTMLSelectElement>;
};

const LoginVerticalSelect: FunctionComponent<Props> = ({
    value,
    touched,
    error,
    onChange,
    onBlur,
}) => {
    const intl = useIntl();
    const [tooltipIsVisible, setTooltipIsVisible] = useState(false);

    const QuestionMark = tooltipIsVisible ? QuestionMarkFilled : QuestionMarkEmpty;
    const options = [
        { label: intl.formatMessage(messages.furniture), value: 'furniture' },
        { label: intl.formatMessage(messages.jewelry), value: 'jewelry' },
        { label: intl.formatMessage(messages.fashion), value: 'fashion' },
        { label: intl.formatMessage(messages.art), value: 'art' },
    ];

    const tooltipMessage = (
        <FormattedMessage
            defaultMessage="If this is your first time logging in and you're unsure of your selling category, you can find this information in your registration email."
            id="login.reset_email_tooltip"
        />
    );

    return (
        <div>
            <div className={styles.verticalSelectionContainer}>
                <div className={styles.verticalSelection}>
                    <p className={styles.fieldLabel}>
                        <FormattedMessage
                            id="login.reset_email_vertical_selection"
                            defaultMessage="Selling Category"
                        />
                    </p>

                    <BasicSelect
                        dataTn="login-vertical-drop-down"
                        errorMessage={!!touched && error}
                        name="vertical"
                        onChange={e => {
                            onBlur('vertical');
                            onChange(e);
                        }}
                        options={options}
                        placeholder=" "
                        size="medium"
                        value={value}
                    />
                </div>

                <div className={styles.tooltip} onClick={() => setTooltipIsVisible(val => !val)}>
                    <QuestionMark className={styles.questionMark} />
                    <Tooltip
                        isVisible={tooltipIsVisible}
                        direction="right"
                        dataTn="login-vertical-tooltip"
                        type="coachmark"
                    >
                        {tooltipMessage}
                    </Tooltip>
                </div>
            </div>
            <div className={styles.mobileHint}>{tooltipMessage}</div>
        </div>
    );
};

export default LoginVerticalSelect;
