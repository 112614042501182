import { createRoot } from 'react-dom/client';
import { RelayEnvironmentProvider } from 'react-relay';
import serverVars from 'server-vars';

import { IntlProvider } from 'dibs-react-intl';
import DealerLoginPage from '../../login/dealer/DealerLoginPage';
import { LOGIN_FORM_TYPES } from '../../login/helpers/loginValidation';
import { getEnvironment } from '../../relayModernEnvironment';

import '../../login/styles/loginGlobalStyles.scss';

const environment = getEnvironment('seller-login');
const formType: LOGIN_FORM_TYPES = serverVars.get('formType');
const root = createRoot(document.getElementById('js-root') as HTMLElement);
root.render(
    <IntlProvider locale={serverVars.get('locale')} messages={serverVars.get('messages')}>
        <RelayEnvironmentProvider environment={environment}>
            <DealerLoginPage formType={formType} />
        </RelayEnvironmentProvider>
    </IntlProvider>
);
