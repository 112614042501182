import { FC, Fragment, useState, FormEvent } from 'react';
import { useRelayEnvironment } from 'react-relay';
import serverVars from 'server-vars';

import { FormattedMessage } from 'dibs-react-intl';
import LoginMessage from '../LoginMessage';
import LoginFormWrapper from '../LoginFormWrapper';
import LoginFormGroup from '../inputs/LoginFormGroup';
import LoginEmailInput from '../inputs/LoginEmailInput';
import LoginVerticalSelect from '../inputs/LoginVerticalSelect';
import LoginSubmit from '../inputs/LoginSubmit';
import LoginServerErrorMessage from '../LoginServerErrorMessage';
import DealerResetPasswordInvalidTokenErrorMessage from './DealerResetPasswordInvalidTokenErrorMessage';
import DealerForgotPasswordConfirmation from './DealerForgotPasswordConfirmation';
import { useLoginFormState } from '../helpers/useLoginFormState';
import { sellerRequestPasswordEmail } from './mutations/sellerRequestPasswordEmail';

const DealerForgotPasswordForm: FC = () => {
    const environment = useRelayEnvironment();

    const {
        errors,
        isValid,
        values,
        touched,
        handleChange,
        handleFieldBlur,
        handleVerticalSelection,
    } = useLoginFormState({
        formType: 'forgotPassword',
        isInternal: false,
    });
    const [submitted, setSubmitted] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(false);

    async function handleSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
        e.preventDefault();

        if (!isValid || !values.vertical) {
            handleFieldBlur();
            return;
        }
        setIsSaving(true);
        try {
            await sellerRequestPasswordEmail(environment, {
                email: values.email,
                vertical: values.vertical,
            });
            setSubmitted(true);
        } catch (err) {
            setIsSaving(false);
            setError(true);
        }
    }

    if (submitted) {
        return <DealerForgotPasswordConfirmation />;
    }

    let message;
    if (error) {
        message = <LoginServerErrorMessage />;
    } else if (serverVars.get('hasInvalidToken')) {
        message = <DealerResetPasswordInvalidTokenErrorMessage />;
    }

    return (
        <Fragment>
            {message && <LoginMessage messageType="error" message={message} />}

            <LoginFormWrapper
                heading={
                    <FormattedMessage
                        id="login.reset_email_heading"
                        defaultMessage="Create New Password"
                    />
                }
                subtitle={
                    <FormattedMessage
                        id="login.reset_email_subtitle"
                        defaultMessage="Step 1 of 3"
                    />
                }
                handleSubmit={handleSubmit}
            >
                <LoginFormGroup shorten>
                    <LoginEmailInput
                        value={values.email}
                        label={
                            <FormattedMessage
                                id="login.reset_email_email"
                                defaultMessage="1stDibs Account Email"
                            />
                        }
                        touched={touched.email}
                        error={errors.email}
                        onBlur={handleFieldBlur}
                        onChange={handleChange}
                    />
                </LoginFormGroup>
                <LoginFormGroup>
                    <LoginVerticalSelect
                        value={values.vertical}
                        touched={touched.vertical}
                        error={errors.vertical}
                        onBlur={handleFieldBlur}
                        onChange={handleVerticalSelection}
                    />
                </LoginFormGroup>
                <LoginSubmit isSaving={isSaving} />
            </LoginFormWrapper>
        </Fragment>
    );
};

export default DealerForgotPasswordForm;
