import { FunctionComponent } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import LoginWell from '../LoginWell';
import { IconWrapper } from 'dibs-elements/exports/IconWrapper';
import Envelope from 'dibs-icons/exports/legacy/Envelope';

import styles from './styles/DealerForgotPasswordConfirmation.scss';

const DealerForgotPasswordConfirmation: FunctionComponent = () => (
    <LoginWell>
        <div className={styles.emailContainer}>
            <IconWrapper
                icon={<Envelope className={styles.inquiryIcon} />}
                circleClass={styles.circleStroke}
                borderWeight="thin"
                size={70}
            />
        </div>
        <BarHeader
            title={
                <FormattedMessage
                    id="login.reset_confirmation_heading"
                    defaultMessage="Check Your Email"
                />
            }
            subtitle={
                <FormattedMessage
                    id="login.reset_confirmation_subtitle"
                    defaultMessage="Step 2 of 3"
                />
            }
        />
        <div className={styles.confirmationContent}>
            <FormattedMessage
                id="login.reset_confirmation_copy"
                defaultMessage="Please check your inbox for an email from us. You'll need to follow the instructions in the email in order to continue."
            />
            <div className={styles.troubleshoot}>
                <p className={styles.troubleshootTitle}>
                    <FormattedMessage
                        id="login.reset_confirmation_tip_heading"
                        defaultMessage="Didn't get an email?"
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="login.reset_confirmation_tip"
                        defaultMessage="Allow 10 minutes for the email to arrive. If you still don't see it, check your spam folder. Double check that you’ve entered the correct email address which can be found in the account set up email you received."
                    />
                </p>
            </div>
        </div>
    </LoginWell>
);

export default DealerForgotPasswordConfirmation;
