import { FunctionComponent, useEffect } from 'react';
import serverVars from 'server-vars';

import { trackSellerPageview } from 'dibs-tracking';
import { FormattedMessage } from 'dibs-react-intl';
import DealerLoginForm from './DealerLoginForm';
import DealerForgotPasswordForm from './DealerForgotPasswordForm';
import DealerResetPasswordForm from './DealerResetPasswordForm';
import { LOGIN_FORM_TYPES } from '../helpers/loginValidation';

type Props = {
    formType: LOGIN_FORM_TYPES;
};

const DealerLoginPage: FunctionComponent<Props> = ({ formType }) => {
    useEffect(trackSellerPageview, []);

    return (
        <>
            {formType === 'login' && (
                <DealerLoginForm
                    successMessage={
                        !!serverVars.get('isLogoutPage') && (
                            <FormattedMessage
                                defaultMessage="You have been successfully logged out."
                                id="dealerLogin.message.logout_success"
                            />
                        )
                    }
                />
            )}
            {formType === 'forgotPassword' && <DealerForgotPasswordForm />}
            {formType === 'resetPassword' && <DealerResetPasswordForm />}
        </>
    );
};

export default DealerLoginPage;
