import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    SellerRequestPasswordEmailInput,
    sellerRequestPasswordEmailMutation$data,
    sellerRequestPasswordEmailMutation as sellerRequestPasswordEmailType,
} from './__generated__/sellerRequestPasswordEmailMutation.graphql';

const mutation = graphql`
    mutation sellerRequestPasswordEmailMutation($input: SellerRequestPasswordEmailInput!) {
        sellerRequestPasswordEmail(input: $input) {
            __typename
        }
    }
`;

export async function sellerRequestPasswordEmail(
    environment: Environment,
    input: SellerRequestPasswordEmailInput
): Promise<sellerRequestPasswordEmailMutation$data> {
    return commitMutation<sellerRequestPasswordEmailType>(environment, {
        mutation,
        variables: { input },
    });
}
