import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CircleQuestionMarkFilledProps = {
    className?: string;
};

const CircleQuestionMarkFilled: FunctionComponent<CircleQuestionMarkFilledProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'circle-question-mark-filled'}
            viewBox="0 0 250 250"
        >
            <path d="M125 249.8c-68.9 0-125-56.1-125-125S56.1-.2 125-.2s125 56.1 125 125c0 69-56.1 125-125 125zm26.4-170.2c-6.7-6.8-15.7-10.2-27.1-10.2-10.8 0-19.5 3.4-26.1 10.1C91.6 86.2 88 94.4 87.5 104l18.2 2.6c1.3-6.7 3.6-11.7 7-15 3.4-3.3 7.6-4.9 12.6-4.9 5.2 0 9.3 1.6 12.4 4.7 3.1 3.1 4.6 6.9 4.6 11.3 0 3.2-.9 6.1-2.6 8.7-1.1 1.7-4.6 5.2-10.3 10.6-5.8 5.4-9.6 10.2-11.5 14.5-1.9 4.3-2.9 9.8-2.9 16.5 0 .6 0 2.4.1 5.4H133c-.1-6.2.4-10.6 1.4-13 1-2.4 3.6-5.6 7.8-9.6 8.1-7.7 13.3-13.8 15.8-18.2 2.5-4.5 3.7-9.2 3.7-14.2-.3-9.1-3.6-17-10.3-23.8zm-27.1 88.2c-6.2 0-11.3 5.1-11.3 11.3s5.1 11.3 11.3 11.3c6.2 0 11.3-5.1 11.3-11.3s-5.1-11.3-11.3-11.3z" />
        </svg>
    );
};
export default CircleQuestionMarkFilled;
