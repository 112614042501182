import { FunctionComponent } from 'react';
import * as React from 'react';
import classNames from 'classnames';

import styles from './main.scss';

type IconWrapperProps = {
    borderWeight?: 'regular' | 'thick' | 'thin';
    circleClass?: string;
    dataTn?: string;
    icon: React.ReactNode;
    onClick?: () => void;
    size?: number;
    wrapperClass?: string;
};

//constants for scaling elements of icon to overall width, based on trial-and-error
// scale of width to circle stroke
const STROKE_SCALE = {
    regular: 0.035,
    thick: 0.045,
    thin: 0.015,
};

//scale of icon width inside bordering circle
const ICON_SCALE = 0.65; //scale of width to icon width, if it's inside a circle

export const IconWrapper: FunctionComponent<IconWrapperProps> = props => {
    const {
        icon,
        borderWeight = 'regular',
        size = 100,
        dataTn,
        onClick,
        wrapperClass,
        circleClass,
    } = props;

    const r = size / 2;
    const circleWidth = size * STROKE_SCALE[borderWeight];
    const scaledSize = `${size * ICON_SCALE}px`;
    const iconStyle = {
        height: scaledSize,
        width: scaledSize,
    };
    const wrapperStyle = {
        height: `${size}px`,
        width: `${size}px`,
    };

    return (
        <div
            className={classNames(styles.wrapper, wrapperClass, { [styles.clickable]: onClick })}
            style={wrapperStyle}
            onClick={onClick}
            data-tn={dataTn}
        >
            <svg
                width={size}
                height={size}
                strokeWidth={circleWidth}
                className={classNames(styles.borderCircle, circleClass)}
            >
                <circle cx={r} cy={r} r={r - circleWidth} />
            </svg>
            <div style={iconStyle}>{icon}</div>
        </div>
    );
};
