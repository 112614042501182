import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { FunctionComponent } from 'react';

type CircleQuestionMarkEmptyProps = {
    className?: string;
};

const CircleQuestionMarkEmpty: FunctionComponent<CircleQuestionMarkEmptyProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'circle-question-mark-empty'}
            viewBox="0 0 250 250"
        >
            <path d="M125-.2C56.1-.2 0 55.9 0 124.8s56.1 125 125 125 125-56 125-125c0-68.9-56.1-125-125-125zm0 241.1c-64 0-116-52.1-116-116 0-64 52.1-116 116-116s116 52 116 116-52 116-116 116z" />
            <path d="M124.2 69.4c-10.8 0-19.5 3.4-26.1 10.1-6.6 6.7-10.2 14.9-10.7 24.5l18.1 2.6c1.3-6.7 3.6-11.7 7-15 3.4-3.3 7.6-4.9 12.6-4.9 5.2 0 9.3 1.6 12.4 4.7 3.1 3.1 4.6 6.9 4.6 11.3 0 3.2-.9 6.1-2.6 8.7-1.1 1.7-4.5 5.2-10.3 10.6-5.7 5.4-9.6 10.2-11.5 14.5s-2.9 9.8-2.9 16.5c0 .6.1 2.4.1 5.4h17.9c-.1-6.2.3-10.6 1.3-13 1-2.4 3.6-5.6 7.8-9.6 8.1-7.6 13.3-13.7 15.8-18.2s3.7-9.2 3.7-14.2c0-9.1-3.4-17-10.1-23.8-6.7-6.8-15.8-10.2-27.1-10.2z" />
            <ellipse
                cx="124.3"
                cy="179.1"
                rx="11.3"
                ry="11.3"
                transform="rotate(-13.286 124.27 179.067)"
            />
        </svg>
    );
};
export default CircleQuestionMarkEmpty;
