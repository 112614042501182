import { graphql, Environment } from 'react-relay/legacy';
import commitMutation from 'relay-commit-mutation-promise';
import {
    SellerResetPasswordInput,
    sellerResetPasswordMutation$data,
    sellerResetPasswordMutation as sellerResetPasswordType,
} from './__generated__/sellerResetPasswordMutation.graphql';

const mutation = graphql`
    mutation sellerResetPasswordMutation($input: SellerResetPasswordInput!) {
        sellerResetPassword(input: $input) {
            errorCode
        }
    }
`;

export async function sellerResetPassword(
    environment: Environment,
    input: SellerResetPasswordInput
): Promise<sellerResetPasswordMutation$data> {
    return commitMutation<sellerResetPasswordType>(environment, {
        mutation,
        variables: { input },
    });
}
